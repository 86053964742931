/* eslint-disable */
/* Warning: this file is autogerated, any changes would be lost */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'





const Blocks = ({ blocks = [], previewUUID }) => {
  return (
    <>
      {useMemo(() => {
        
        return blocks
      }, [blocks, previewUUID]).map((block, i) => {
        const children = block.innerBlocks ? <Blocks blocks={block.innerBlocks} previewUUID={previewUUID} /> : null;

        

        return null;
      })}
    </>
  );
};

export default ({ previewUUID }) => {

  const { wpProject: { blocks } } = useStaticQuery(graphql`
    fragment Blocks520 on WpBlock { __typename   }
    query {
      wpProject(id: {eq: "520"}) {
        blocks {
          ...Blocks520
        }
      }
    }`)

  return <Blocks blocks={blocks} previewUUID={previewUUID} />
}
